import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const ProgressSpinner = styled.div`
    animation: ${rotate} 0.7s infinite linear;
    border: 3px solid #ffffff;
    border-left-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    border-top-color: rgba(0, 0, 0, 0);
    height: 18px;
    margin: auto;
    opacity: 0.8;
    width: 18px;
`;

export default ProgressSpinner;