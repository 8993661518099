const makeActionCreator = ((type, ...argNames) =>
    (...args) => {
        const action = { type }
        argNames.forEach((_, index) => {
            action[argNames[index]] = args[index]
        });
        return action;
    });

export const actionTypes = {
    LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
    LOGIN_USER_RESPONSE: 'LOGIN_USER_RESPONSE',
    LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',

    SUBSCRIBE_USER_REQUEST: 'SUBSCRIBE_USER_REQUEST',
    SUBSCRIBE_USER_RESPONSE: 'SUBSCRIBE_USER_RESPONSE',
    SUBSCRIBE_USER_ERROR: 'SUBSCRIBE_USER_ERROR',

    LIST_ROOMS_REQUEST: 'LIST_ROOMS_REQUEST',
    LIST_ROOMS_RESPONSE: 'LIST_ROOMS_RESPONSE',
    LIST_ROOMS_ERROR: 'LIST_ROOMS_ERROR',

    EXIT_ROOM_REQUEST: 'EXIT_ROOM_REQUEST',
    EXIT_ROOM_RESPONSE: 'EXIT_ROOM_RESPONSE',

    GET_BALANCE_REQUEST: 'GET_BALANCE_REQUEST',
    GET_BALANCE_RESPONSE: 'GET_BALANCE_RESPONSE',

    SELECT_ROOM_TYPE: 'SELECT_ROOM_TYPE',

    SET_AVATARS_LIST: 'SET_AVATARS_LIST',

    CHANGE_AVATAR_REQUEST: 'CHANGE_AVATAR_REQUEST',
}

export const loginUserRequest = makeActionCreator(actionTypes.LOGIN_USER_REQUEST, 'data');
export const loginUserResponse = makeActionCreator(actionTypes.LOGIN_USER_RESPONSE, 'data');
export const loginUserError = makeActionCreator(actionTypes.LOGIN_USER_ERROR, 'error');

export const subscribeUserRequest = makeActionCreator(actionTypes.SUBSCRIBE_USER_REQUEST);
export const subscribeUserResponse = makeActionCreator(actionTypes.SUBSCRIBE_USER_RESPONSE);
export const subscribeUserError = makeActionCreator(actionTypes.SUBSCRIBE_USER_ERROR, 'error');

export const listRoomsRequest = makeActionCreator(actionTypes.LIST_ROOMS_REQUEST);
export const listRoomsResponse = makeActionCreator(actionTypes.LIST_ROOMS_RESPONSE, 'data');
export const listRoomsError = makeActionCreator(actionTypes.LIST_ROOMS_ERROR);

export const exitRoomRequest = makeActionCreator(actionTypes.EXIT_ROOM_REQUEST);
export const exitRoomResponse = makeActionCreator(actionTypes.EXIT_ROOM_RESPONSE);

export const getBalanceRequest = makeActionCreator(actionTypes.GET_BALANCE_REQUEST);
export const getBalanceResponse = makeActionCreator(actionTypes.GET_BALANCE_RESPONSE, 'data');

export const selectRoomType = makeActionCreator(actionTypes.SELECT_ROOM_TYPE, 'roomType');

export const setAvatarsList = makeActionCreator(actionTypes.SET_AVATARS_LIST, 'avatars');

export const changeAvatarRequest = makeActionCreator(actionTypes.CHANGE_AVATAR_REQUEST, 'avatar');
