import React from 'react';
import styled from 'styled-components';

import { PlayButton } from "../common/Buttons";

const Image = styled.div`
    background: ${props => `center / contain no-repeat url('img/${props.id}.jpg')`};
    border-radius: 20px;
    border: 1px solid #ffffff61;
    height: 200px;
    width: 200px;

    @media (max-width: 520px) {
        height: 152px;
        width: 152px;
    }
`;

const TableStatus = styled.div`
    &::after {
        color: ${props => `${props.online ? "#46ff46" : "red"}`};
        content: ${props => `'${props.online ? "⬤ online" : "⬤ offline"}'`};
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Description = styled.div`
    color: #ffffffb5;
    font-size: calc(12px + 0.3vw);

    @media (min-width: 1360px) {
        font-size: 16px;
    }
`;

const PropertiesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    position: relative;
    max-width: 300px;
`;

const CardTop = styled.div`
    display: flex;

    @media (max-width: 520px) {
        flex-direction: column;
    }
`;

const CardBottom = styled.div`
    display: flex;
`;

const Card = styled.div`
    background: #141159;
    border-radius: 20px;
    box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 260px;
    margin: 12px;
    padding: 24px 12px 36px 12px;

    h3 {
        display: none;
        font-size: calc(10px + 1vw);
        font-style: normal;
        font-weight: normal;
        margin-top: 0;
        text-transform: capitalize;

        &.right-header {
            display: block;
            line-height: 47px;
            margin-bottom: 16px;
        }
    }

    @media (max-width: 520px) {
        height: auto;
        padding: 24px 12px 24px 12px;
        width: 80%;

        ${PropertiesWrapper} {
            margin-bottom: 12px;
            margin-left: 0;
            margin-top: 12px;
            max-width: 100%;
        }

        h3 {
            &.top-header {
                display: block;
                font-size: calc(14px + 1vw);
                word-break: break-all;
            }

            &.right-header {
                display: none;
            }
        }
    }

    @media (min-width: 1360px) {
        h3 {
            font-size: 26px;
        }
    }
`;

export const GameCard = ({ className, gameProps, onPlayClicked }) => {

    return (
        <Card className={className}>
            <h3 className="top-header">{gameProps.tableName}</h3>
            <CardTop>
                <ImageWrapper>
                    <Image id={gameProps.tableId} />
                    <TableStatus online={gameProps.online}></TableStatus>
                </ImageWrapper>
                <PropertiesWrapper>
                    <h3 className="right-header">{gameProps.tableName}</h3>
                    <Description>{gameProps.description}</Description>
                </PropertiesWrapper>
            </CardTop>
            <CardBottom>
                <PlayButton disabled={!gameProps.online} onClick={(e) => onPlayClicked(e, gameProps)}>Play</PlayButton>
            </CardBottom>
        </Card>
    )
}