import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { changeAvatarRequest } from '../../actions/index';

export const avatarsPath = "https://stargame.one/pvp/avatars";

const modalStyle = {
    content: {
        background: 'transparent',
        border: 'none',
        bottom: 0,
        height: '100%',
        left: 0,
        overflow: 'inherit',
        padding: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
    },
    overlay: {
        background: 'transparent',
        position: 'absolute',
        opacity: 1,
        zIndex: 100
    }
};

const Image = styled.img`
    border-radius: 6px;
    cursor: pointer;
    margin: 4px;
    width: 128px;
    height: 128px;

    ${props => {
        if (props.active) {
            return `
                background: white;
            `;
        }
    }}
`;

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
`;

function Avatars({ session, selector, changeAvatarRequest }) {

    const onAvatarClick = (e, avatar) => {
        e.preventDefault();
        changeAvatarRequest(avatar);
    }

    return (
        <Modal
            isOpen={true}
            parentSelector={() => document.querySelector('#live-selector')}
            style={{ ...modalStyle, content: { ...modalStyle.content } }}
        >
            <Wrapper>
                {selector.avatars.map((item, index) => {
                    const path = item.url;
                    return (
                        <Image
                            key={index}
                            alt={item.key}
                            src={path}
                            active={session.avatar === item.key}
                            onClick={e => onAvatarClick(e, item.key)}
                        ></Image>
                    )
                })}

            </Wrapper >
        </Modal>
    );
}

function mapStateToProps(state) {
    const session = state.authentication;
    const selector = state.selector;
    return { session, selector };
}

function mapDispatchToProps(dispatch) {
    return {
        changeAvatarRequest: (avatar) => dispatch(changeAvatarRequest(avatar))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Avatars);
