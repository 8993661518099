import React from 'react';
import styled from 'styled-components';

export const HeaderControlButton = styled.div`
    background-image: ${props => `url(${require(`./img/${props.icon}.svg`)});`};
    cursor: pointer;
    height: 1.5vw;
    width: 1.5vw;
    min-height: 18px;
    min-width: 18px;

    &:hover {
        opacity: 0.6;
    }

    @media (hover: none) {
        &:hover {
            opacity: inherit;
        }
        &:active {
            opacity: 0.6;
        }
    }
`;

export const CloseButton = ({ className, onClick }) => (
    <HeaderControlButton className={className} onClick={onClick} icon="close" />
)

export const FullscreenButton = ({ onClick }) => (
    <HeaderControlButton onClick={onClick} icon="fullscreen" />
)

export const PlayButton = styled.button`
    background: transparent;
    border-radius: 30px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    height: 48px;
    letter-spacing: 0.1em;
    line-height: 35px;
    outline: none;
    text-transform: uppercase;
    width: 200px;

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    &:hover {
        background: #7BAAED;
    }

    @media (hover: none) {
        &:hover {
            background: transparent;
        }
        &:active {
            background: #7BAAED;
        }
    }
`;