import styled from 'styled-components';

export const UserBlock = styled.div`
    display: flex;
    z-index: 1;

    .user-block__balance {
      align-items: center;
      color: #ffd700;
      display: flex;

      &::before {
        background: url(${require(`./img/coin.svg`)}) no-repeat;
        background-size: contain;
        content: "";
        display: block;
        height: 4vw;
        margin-right: 6px;
        max-height: 64px;
        max-width: 64px;
        width: 4vw;
      }
  }

  .user-block__avatar {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: calc(0.6em + 0.6vw);
    margin-right: 1em;
    max-width: 16vw;
    word-break: break-all;

    &::before {
      background: ${props => props.avatar ? `center / contain no-repeat url(${`${props.avatar}`});` : 'transparent'};
      background-size: contain;
      content: "";
      display: block;
      height: 4vw;
      margin-right: 6px;
      max-height: 64px;
      max-width: 64px;
      min-width: 4vw;
      width: 4vw;
    }
  }

  .user-block__balance, .user-block__avatar {
    @media (max-width: 1240px) {
        max-width: 300px;
    }

    @media (max-width: 808px) {
        max-width: 120px;
    }

    @media (max-width: 768px) {
        max-width: 300px;
        font-size: 1em;
    }

    @media (max-width: 768px) and (orientation: portrait) {
        &::before {
            width: 42px;
            height: 42px;
        }
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    right: 5%;
  }
}
`;

export const HeaderControls = styled.div`
    display: flex;
    position: fixed;
    top: 8px;
    right: 8px;
`;

export const GameFrame = styled.iframe`
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
`;

export const ProgressWrapper = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    left: 50%;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const NoRoomsNotification = styled.div`
    font-size: 1.5em;
    left: 50%;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const RoomsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
`;

export const Header = styled.header`
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    flex: 0 1 auto;
    min-height: 120px;
    padding: 12px;
    position: relative;
    width: 100%;

    @media screen and (orientation: landscape) {
        min-height: 60px;
    }

    @media (max-width: 768px) and (orientation: landscape) {
        min-height: 80px;
    }

    @media (min-width: 1048px) {
        min-height: calc(5vw + 1.5em);
    }
}`
