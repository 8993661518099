import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Swipeable } from "react-swipeable";

import { listRoomsRequest, selectRoomType } from '../../actions/index';

const ListItem = styled.li`
    cursor: pointer;
    margin: 0 5px;
    opacity: 0.8;
    outline: none;
    padding: 0 20px 0 15px !important;
    text-align: center;
    text-decoration: none;
    user-select: none;
    white-space: nowrap;

    ${props => {
        if (props.active) {
            return `
                font-size: 50px;
                opacity: 1;

                @media (max-width: 1240px) {
                    font-size: 24px;
                }

                @media (max-width: 1048px) {
                    font-size: 24px;
                }
            `;
        }
    }}
`;

const List = styled.ul`
    --n: 1;
    align-items: center;
    color: #FFFFFF;
    display: flex;
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: normal;
    line-height: 35px;
    list-style-type: none;
    margin: 0;
    padding: 8px;
    position: sticky;
    width: auto;

    @media (max-width: 1240px) {
        font-size: 24px;
    }

    @media (max-width: 1048px) {
        font-size: 18px;
    }

    @media screen and (orientation: portrait) and (max-width: 726px) {
        width: 100%;
        width: calc(var(--n) * 100%);
        transform: translate(calc(var(--tx, 0px) + var(--i, 0) / var(--n) * -30%));

        &.smooth {
            transition: transform calc(var(--f, 1) * .5s) cubic-bezier(1, 1.59, .61, .74);
        }

        ${ListItem} {
            width: 100%;
            width: calc(30% / var(--n));
        }
    }
`;

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const roomTypes = {
    "roulette": "Roulette",
    "lottery": "Lotteries",
    "baccarat": "Baccarat",
    "poker": "Poker",
    "generic": "Other"
};

const NEXT = "NEXT";
const PREV = "PREV";

function Menu({ selector, getRooms, selectRoomType, className }) {

    const [pos, setPos] = useState(0);
    const menuRef = useRef(null);
    const items = Object.keys(selector.rooms);
    const numItems = items.length;

    const slide = (data, dir) => {
        if (dir === NEXT) {
            setPos(pos === 1 ? 0 : pos + 1);
        } else {
            setPos(pos === 0 ? 1 : pos - 1);
        }

        const dx = -data.deltaX
        let s = Math.sign(dx);
        let f = +(s * dx / window.innerWidth).toFixed(2);
        let i = pos;

        if ((i > 0 || s < 0) && (i < numItems - 1 || s > 0) && f > .2) {
            menuRef.current.style.setProperty('--i', i -= s);
            f = 1 - f
        }

        menuRef.current.style.setProperty('--tx', '0px');
        menuRef.current.style.setProperty('--f', f);
        menuRef.current.classList.toggle('smooth', true);
    };

    const sliding = data => {
        menuRef.current.style.setProperty('--tx', `${Math.round(-data.deltaX)}px`)
    }

    const swipeConfig = {
        onSwipedLeft: (data) => slide(data, NEXT),
        onSwipedRight: (data) => slide(data, PREV),
        onSwiping: (data) => sliding(data),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    };

    const onMenuItemClick = (e, item, index) => {
        e.preventDefault();
        if (index > pos) {
            slide({ deltaX: +window.innerWidth / 2 }, NEXT);
        }
        if (index < pos) {
            slide({ deltaX: -window.innerWidth / 2 }, PREV);
        }
        selectRoomType(item);
    }

    useEffect(() => {
        const timer = setInterval(() => {
            getRooms();
        }, 15000);

        menuRef.current.style.setProperty('--n', numItems);
        getRooms();

        return () => {
            clearInterval(timer);
        }
    }, [getRooms, numItems]);

    return (
        <div style={{ width: "100%" }} className={className}>
            <Swipeable {...swipeConfig}>
                <Wrapper>
                    <List ref={menuRef}>
                        {items.map((item, index) => {
                            const type = roomTypes[item];
                            return (
                                <ListItem
                                    key={index}
                                    active={selector.selectedType === item}
                                    onClick={e => onMenuItemClick(e, item, index)}
                                >{type}</ListItem>
                            )
                        })}
                    </List>
                </Wrapper>
            </Swipeable>
        </div >
    );
}

function mapStateToProps(state) {
    const selector = state.selector;
    return { selector };
}

function mapDispatchToProps(dispatch) {
    return {
        getRooms: () => dispatch(listRoomsRequest()),
        selectRoomType: (roomType) => dispatch(selectRoomType(roomType)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
