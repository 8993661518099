import { all, call, fork, put, take, takeEvery, select } from 'redux-saga/effects';

import { actionTypes } from '../actions/index';
import * as actions from '../actions/index';
import * as http from '../service/http';
import * as websocket from '../service/websocket';

const debug =
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("debug");

const gameIndexLive = 11;
const query = window.Utils.parseQuery(window.location.search.substring(1));
const socket = websocket.init(debug);

const getAuth = (state) => state.authentication;

function assureProperty(obj, key) {
    window.Utils.assert(key in obj, `missing required '${key}' parameter in the query`);
    return obj[key];
}

function* watchLoginUserRequest() {
    while (true) {
        const data = yield take(actionTypes.LOGIN_USER_REQUEST);
        const { username, password, remember, restore } = { ...data.data };

        yield fork(function* () {
            try {
                const auth = yield select(getAuth);
                if (auth.standalone) {
                    if (debug && query.server) {
                        socket.host = query.server;
                    }
                    const session = restore ?
                        yield call(http.checkLogin) :
                        yield call(http.login, username, password);
                    socket.sid = session.sid;
                    socket.key1 = session.key1;
                    socket.key2 = session.key2;
                } else {
                    socket.host = assureProperty(query, "server");
                    if (query.sid !== undefined &&
                        query.key1 !== undefined &&
                        query.key2 !== undefined) {
                        socket.sid = query.sid;
                        socket.key1 = query.key1;
                        socket.key2 = query.key2;
                    } else {
                        const login = assureProperty(query, "login");
                        const password = assureProperty(query, "password");
                        const { sid, key1, key2 } = yield call(async () => {
                            return await window.HttpApi.login(login, password);
                        });
                        socket.sid = sid;
                        socket.key1 = key1;
                        socket.key2 = key2;
                    }
                }
                yield call([socket, socket.connect]);
                socket.walletApiUserName = query.username;
                const loginResponse = yield call([socket, socket.loginSid]);
                loginResponse.key1 = socket.key1;
                loginResponse.key2 = socket.key2;

                remember && localStorage.setItem("stargame.live.autologin", true);

                if (auth.standalone) {
                    socket.onDisconnect = () => {
                        localStorage.removeItem("stargame.live.autologin");
                        window.location.reload();
                    };
                } else {
                    socket.onDisconnect = () => {
                        window.parent.postMessage({ msg: 'closeGame' }, '*');
                    };
                }

                yield put(actions.loginUserResponse(loginResponse));
            } catch (error) {
                localStorage.removeItem("stargame.live.autologin");
                yield put(actions.loginUserError());
            }
        });
    }
}

function* watchSubscribeUserRequest() {
    yield takeEvery(actionTypes.SUBSCRIBE_USER_REQUEST, function* () {
        try {
            yield call([socket, socket.gameSubscribe], gameIndexLive);
            yield put(actions.subscribeUserResponse());
        } catch (error) {
            yield put(actions.subscribeUserError(error));
        }
    });
}

function* watchListRoomsRequest() {
    yield takeEvery(actionTypes.LIST_ROOMS_REQUEST, function* () {
        try {
            const listRoomsResponse = yield call([socket, socket.liveGamesListTables]);
            yield put(actions.listRoomsResponse(listRoomsResponse));
        } catch (error) {
            yield put(actions.listRoomsError());
        }
    });
}

function* watchGetBalanceRequest() {
    yield takeEvery(actionTypes.GET_BALANCE_REQUEST, function* () {
        try {
            const getBalanceResponse = yield call([socket, socket.getBalance]);
            yield put(actions.getBalanceResponse(getBalanceResponse));
        } catch (error) {
            yield put(actions.getBalanceResponse(null));
        }
    });
}

function* watchExitRoomRequest() {
    yield takeEvery(actionTypes.EXIT_ROOM_REQUEST, function* () {
        try {
            yield call([socket, socket.liveGamesDisconnectTable]);
        } catch (error) { }
    });
}

function* watchChangeAvatarRequest() {
    yield takeEvery(actionTypes.CHANGE_AVATAR_REQUEST, function* (action) {
        try {
            yield call([socket, socket.setPlayerPrefsRequest], { avatar: action.data });
        } catch (error) { }
    });
}

export default function* saga() {
    yield all([
        watchLoginUserRequest(),
        watchSubscribeUserRequest(),
        watchListRoomsRequest(),
        watchGetBalanceRequest(),
        watchExitRoomRequest(),
        watchChangeAvatarRequest()
    ]);
}
