import styled from 'styled-components';

export const ProgressWrapper = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    left: 50%;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const BrandFooter = styled.div`
    bottom: 0;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    left: 0;
    margin-block-end: 1em;
    margin-block-start: 1em;
    position: fixed;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    span:nth-child(2) {
        background-clip: content-box;
        background: #c60000;
        font-size: 18px;
        margin-left: 4px;
        padding-left: 2px;
        padding-right: 2px;
        text-transform: lowercase;
    }

    @media screen and (max-height: 480px) {
        display: none;
    }
`;

export const CircleCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0px 20px 3px;
    position: relative;

    span {
        color: #ddd;
        display: inline-block;
        font-size: 16px;
        margin: 4px 0 0 20px;
    }

    label {
        background: transparent;
        border-radius: 50%;
        border: 1px solid #ccc;
        box-sizing: content-box;
        color: #ddd;
        cursor: pointer;
        height: 28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 28px;

        &:after {
            border-radius: 8px;
            border: 8px solid red;
            content: "";
            height: 0px;
            left: 6px;
            opacity: 0;
            position: absolute;
            top: 6px;
            width: 0px;
        }
    }

    input[type="checkbox"] {
        visibility: hidden;
    }

    input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }
`;

export const FormWrapper = styled.div`
    background: transparent;
    margin: 0 auto 0;
    padding: 10px 10px 10px;
    position: relative;
    width: 400px;

    &:before {
        bottom: -3px;
        content: "";
        left: -3px;
        position: absolute;
        right: -3px;
        top: -3px;
        z-index: -1;
    }

    h1 {
        color: #c0bfbfbf;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        font-weight: bold;
        margin: 0 0 20px 0px;
        text-align: center;
    }

    p {
        margin: 8px 0 0;
    }

    input[type="text"],
    input[type="password"] {
        padding: 0 0 0 8px;
        width: 100%;
        height: 40px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid;
        border-color: #c4c4c4 #d1d1d1 #d4d4d4;
        border-radius: 4px;
        font-size: 16px;

        &:focus {
            outline: none;
            border-radius: 4px;
            border: 1px solid #89bfeb;
        }
    }

    button {
        width: 160px;
        height: 40px;
        margin: auto;
        margin-bottom: 8px;
        cursor: pointer;
        display: block;
        box-shadow: inset 0px 1px 0px 0px #ffffff;
        background: linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
        background-color: #ededed;
        border-radius: 6px;
        border: 1px solid #dcdcdc;
        color: #777777;
        font-family: Arial;
        font-size: 15px;
        font-weight: bold;
        padding: 6px 24px;
        text-decoration: none;
        text-shadow: 0px 1px 0px #ffffff;

        span {
            transform: translateX(-50%);
            position: absolute;
            left: 50%;
        }

        @media (hover: hover) {
            &:hover {
                background: linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
                background-color: #dfdfdf;
            }
        }

        &:active {
            position: relative;
            top: 1px;
        }

        &:disabled {
            outline: 0;
            background-size: contain;
            opacity: 0.5;
            cursor: text;
            pointer-events: none;
        }

        &:after {
            content: "";
            display: inline-block;
            position: relative;
        }
    }

    @media screen and (max-width: 640px) {
        width: 75%;
    }

    @media screen and (max-height: 360px) and (min-width: 480px) {
        h1 {
            display: none;
        }
    }
`;

export const LoginError = styled.div`
    text-align: center;
    label {
        color: #ddd;
        display: inline-block;
        font-size: 16px;
        margin-top: 8px;
    }
`;

export const LoginContainer = styled.div`
    height: 100%;
    position: absolute;
    width: 100%;

    * {
        box-sizing: border-box;
    }
`;
