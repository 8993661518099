import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import { GlobalStyle } from './App.style';
import Lobby from '../lobby/Lobby';
import Login from '../login/Login';

function App({ standalone, authenticated, loginFailed }) {

    useEffect(() => {
        if (loginFailed && !standalone) {
            window.parent.postMessage({ msg: "closeGame" }, "*");
        }
    }, [standalone, loginFailed]);

    return (
        <React.Fragment>
            <GlobalStyle />
            {!authenticated ?
                <Login /> :
                <Lobby />
            }
        </React.Fragment >
    );
}

function mapStateToProps(state) {
    const session = state.authentication;
    const authenticated = session.uid !== undefined && session.uid !== null;
    const loginFailed = session.failed;
    const standalone = session.standalone;

    return { standalone, authenticated, loginFailed };
}

export default connect(mapStateToProps, null)(App);
