
import { actionTypes } from "../actions/index";
import { createReducer } from "./utils";

export const AVATAR_SELECTOR = 1;
export const ROOMS_MENU = 2;

const ROULETTE = "roulette";
const LOTTERY = "lottery";
const BACCARAT = "baccarat";
const POKER = "poker";
const GENERIC = "generic";

const initialState = {
    rooms: {},
    selectedType: ROULETTE,
    balance: null,
    waitSubscribe: true,
    avatars: []
};

export const selector = createReducer(initialState, {
    [actionTypes.SUBSCRIBE_USER_REQUEST]: (state) => {
        return { ...state, failed: false, waitSubscribe: true, subscribed: false };
    },
    [actionTypes.SUBSCRIBE_USER_RESPONSE]: (state) => {
        return { ...state, failed: false, waitSubscribe: true, subscribed: true }
    },
    [actionTypes.SUBSCRIBE_USER_ERROR]: (state) => {
        return { ...state, failed: true, waitSubscribe: false, subscribed: false };
    },
    [actionTypes.LIST_ROOMS_RESPONSE]: (state, action) => {
        const tablesList = action.data.toObject().tablesList;
        const rooms =
            tablesList
                .filter(table => table.tableId !== '0')
                .reduce((acc, curr) => {
                    if (curr.liveRouletteTable) {
                        curr.type = ROULETTE;
                    } else if (curr.liveKenoTable) {
                        curr.type = LOTTERY;
                    } else if (curr.evolutionTable) {
                        if (curr.evolutionTable.liveBaccaratTable || curr.tableId === "w33p1pzvahymhfj6" || curr.tableId === "zz15hk1j38fe1ay9") {
                            curr.type = BACCARAT;
                        } else if (curr.evolutionTable.liveRouletteTable || curr.tableId === "3v12py4p4aihrezp" || curr.tableId === "99tk73qhhc826l26") {
                            curr.type = ROULETTE;
                        } else if (curr.evolutionTable.livePokerTable) {
                            curr.type = POKER;
                        } else {
                            curr.type = GENERIC;
                        }
                    }
                    if (curr.type) {
                        acc[curr.type] = acc[curr.type] || [];
                        acc[curr.type].push(curr);
                    }
                    return acc;
                }, {});

        let selectedType = state.selectedType;

        if (rooms[selectedType] === undefined) {
            selectedType = Object.keys(rooms)[0];
        }

        return { ...state, waitSubscribe: false, rooms, selectedType: selectedType };
    },
    [actionTypes.LIST_ROOMS_ERROR]: (state) => {
        return { ...state, failed: true };
    },
    [actionTypes.SELECT_ROOM_TYPE]: (state, action) => {
        return { ...state, selectedType: action.roomType };
    },
    [actionTypes.SET_AVATARS_LIST]: (state, action) => {
        const avatars = [...action.avatars];
        return { ...state, avatars };
    },
    [actionTypes.GET_BALANCE_RESPONSE]: (state, action) => {
        return {
            ...state,
            balance: action.data !== null ? action.data.getBalanceCoins() : null
        };
    },
});
