import { connect } from 'react-redux';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import React from 'react';

import {
    BrandFooter,
    CircleCheckbox,
    FormWrapper,
    LoginContainer,
    LoginError,
    ProgressWrapper,
} from "./Login.style";
import { isDesktop } from '../../utils/browser';
import { loginUserRequest } from '../../actions/index';
import ProgressSpinner from '../common/ProgressSpinner';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            remember: false,
            layoutName: "default",
            inputName: "username",
            hasRestoreChance: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hostName = window.location.hostname.split('.').reverse()[1];
    }

    componentDidMount() {
        if (this.props.standalone) {
            const restore = localStorage.getItem("stargame.live.autologin") === "true";
            if (restore) {
                this.setState({ hasRestoreChance: true });
                this.props.login({ restore: true });
            }
        } else {
            this.props.login({});
        }
    }

    onChangeAll(inputs) {
        this.setState({ [this.state.inputName]: inputs[this.state.inputName] });
    }

    onKeyPress(button) {
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        else if (button === "{enter}") this.handleSubmit();
    };

    handleShift() {
        const layoutName = this.state.layoutName;
        this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
        });
    };

    setActiveInput(e) {
        const { name } = e.target;
        this.setState({
            inputName: name
        });
    }

    handleChange(e) {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            this.setState({ [name]: checked });
        } else {
            this.setState({ [name]: value }, () => {
                this.keyboard && this.keyboard.setInput(value);
            });
        }
    }

    handleSubmit(e) {
        e && e.preventDefault();
        this.setState({ hasRestoreChance: false });
        const { username, password, remember } = this.state;

        if (username && password) {
            this.props.login({ username, password, remember });
        }
    }

    render() {
        const { username, password, remember } = this.state;
        const showProgressOnly =
            this.props.waitRestore || (!this.props.standalone && this.props.waitLogin)

        if (showProgressOnly) {
            return (
                <LoginContainer>
                    <ProgressWrapper>
                        <ProgressSpinner />
                    </ProgressWrapper>
                    <BrandFooter>
                        <span>{this.hostName}</span>
                        <span>live</span>
                    </BrandFooter>
                </LoginContainer>
            )
        } else {
            return (
                <LoginContainer>
                    <FormWrapper>
                        <h1>Sign in</h1>
                        <form onSubmit={this.handleSubmit}>
                            <p>
                                <input
                                    autoComplete="off"
                                    className="keyboard"
                                    disabled={this.props.waitLogin}
                                    id="username"
                                    name="username"
                                    onChange={this.handleChange}
                                    onFocus={this.setActiveInput.bind(this)}
                                    placeholder="Login"
                                    required
                                    type="text"
                                    value={username}
                                />
                            </p>
                            <p>
                                <input
                                    autoComplete="off"
                                    className="keyboard"
                                    disabled={this.props.waitLogin}
                                    name="password"
                                    onChange={this.handleChange}
                                    onFocus={this.setActiveInput.bind(this)}
                                    placeholder="Password"
                                    required
                                    type="password"
                                    value={password}
                                />
                            </p>
                            <CircleCheckbox>
                                <input
                                    disabled={this.props.waitLogin}
                                    id="remember_me"
                                    name="remember"
                                    onChange={this.handleChange}
                                    type="checkbox"
                                    value={remember}
                                />
                                <label htmlFor="remember_me"></label>
                                <span>Remember&nbsp;me</span>
                            </CircleCheckbox>
                            {!this.props.waitLogin ? <button><span>Submit</span></button> : <ProgressSpinner />}
                        </form>
                        {this.props.failed && !this.state.hasRestoreChance &&
                            <LoginError>
                                <label>
                                    Sorry, unable to login. Please reenter your credentials and try again.
                                </label>
                            </LoginError>
                        }
                    </FormWrapper>
                    {isDesktop() && !this.props.waitLogin &&
                        <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            inputName={this.state.inputName}
                            layoutName={this.state.layoutName}
                            onChangeAll={inputs => this.onChangeAll(inputs)}
                            onKeyPress={button => this.onKeyPress(button)}
                        />
                    }
                    <BrandFooter>
                        <span>{this.hostName}</span>
                        <span>live</span>
                    </BrandFooter>
                </LoginContainer>
            )
        }
    }
}

function mapStateToProps(state) {
    const { standalone, waitRestore, waitLogin, failed } = state.authentication;
    return { standalone, waitRestore, waitLogin, failed };
}

function mapDispatchToProps(dispatch) {
    return {
        login: payload => dispatch(loginUserRequest(payload))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
