import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    body {
        background: linear-gradient(323.09deg, #070721 16.18%, #100F46 91.76%);
        min-height: 100%;
        padding: 0;
        margin: 0;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        color: #FFFFFF;
        overflow: hidden;
    }

    .simple-keyboard {
        background: transparent;
        color: #000000;
        left: 50%;
        transform: translateX(-50%);
        top: auto;
        bottom: 80px;
        position: fixed;
        width: 70%;
        .hg-button {
        opacity: 0.6;
    }

    @media screen and (max-height: 620px) {
        .simple-keyboard {
            display: none;
        }
    }
}
`;
