import { combineReducers } from 'redux';

import { authentication } from './authentication';
import { selector } from './selector';

const rootReducer = () => combineReducers({
    authentication,
    selector
});

export default rootReducer;
