import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import { Provider } from "react-redux";
import App from './components/app/App';
import store from "./store/index";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
