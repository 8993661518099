
import { actionTypes } from "../actions/index";
import { createReducer } from "./utils";

const query = window.Utils.parseQuery(window.location.search.substring(1));

const initialState = {
    standalone: window.parent === window,
    embedded: query.embedded === 'true',
    external: window.location.hostname.includes("apiserver.solutions"),
};

export const authentication = createReducer(initialState, {
    [actionTypes.LOGIN_USER_REQUEST]: (state, action) => {
        return {
            ...state,
            uid: null,
            waitRestore: action.data.restore,
            waitLogin: true,
            failed: false
        };
    },
    [actionTypes.LOGIN_USER_RESPONSE]: (state, action) => {
        const uid = action.data.getUid();
        const sid = action.data.getSid();
        const hallId = action.data.getHallId();
        const avatar = action.data.getAvatar();
        const name = action.data.getName();
        return {
            ...state,
            waitRestore: false,
            waitLogin: false,
            failed: false,
            uid,
            sid,
            hallId,
            key1: action.data.key1,
            key2: action.data.key2,
            name,
            avatar
        };
    },
    [actionTypes.LOGIN_USER_ERROR]: (state) => {
        return {
            ...state,
            waitRestore: false,
            waitLogin: false,
            failed: true,
            pending: false,
            uid: null,
            sid: null,
            hallId: null
        };
    },
    [actionTypes.CHANGE_AVATAR_REQUEST]: (state, action) => {
        return { ...state, avatar: action.data };
    },
});
