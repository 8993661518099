const serverHostDebug = 'wss://58.stargame.games:7274';
const serverHostRelease = 'wss://30.stargamegames.com:7275';

export function init(debug) {
    if (!window.socket) {
        const host = debug ? serverHostDebug : serverHostRelease;
        window.socket = new window.Socket(null);
        window.socket.host = host;
    }
    return window.socket;
};
