import md5 from 'md5';

const version = '0.6';
const httpServerHost = 'https://apiserver.solutions';

function xmlNodeValue(xmlDoc, name) {
    const elements = xmlDoc.getElementsByTagName(name);
    if (elements.length === 0 || elements[0].childNodes.length === 0) {
        return "";
    }
    if (name === "social") {
        const result = [];
        elements[0].childNodes.forEach(node => {
            if (node.nodeType === 1) {
                result.push({
                    type: node.nodeName,
                    url: node.textContent
                });
            }
        });

        return result;
    }
    return elements[0].childNodes[0].nodeValue;
}

export async function checkLogin() {

    const xml =
        `<request>
            <version>${version}</version>
            <operation>checkbysidcookie</operation>
            <show_packet>1</show_packet>
        </request>`

    const payload = {
        reg_xml: btoa(xml),
        reg_sign: md5("hall_secret" + xml + "hall_secret")
    };

    const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
    };

    const response = await fetch(`${httpServerHost}/remotecab_session`, options);
    const text = await response.text();
    const xmlText = '<?xml version="1.0"?>' + atob(text.replace(/\n/g, ''));
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "text/xml");

    const success = xmlNodeValue(xmlDoc, "status") === "success";

    if (!success) {
        throw new Error("login failed");
    }

    let chatLinks = [];
    let promoenabled = "0";
    let promobalance = "0";
    try {
        chatLinks = xmlNodeValue(xmlDoc, "social") || [];
        promoenabled = xmlNodeValue(xmlDoc, "promoenabled") || "0";
        promobalance = xmlNodeValue(xmlDoc, "promobalance") || "0";
    } catch (e) { }

    let walletApi = "";
    try {
        walletApi = xmlNodeValue(xmlDoc, "walletapi") || "";
    } catch (e) { }

    return {
        uid: +xmlNodeValue(xmlDoc, "uid"),
        sid: +xmlNodeValue(xmlDoc, "sessionid"),
        key1: +xmlNodeValue(xmlDoc, "key1"),
        key2: +xmlNodeValue(xmlDoc, "key2"),
        hall_id: +xmlNodeValue(xmlDoc, "hall_id"),
        remote_url: xmlNodeValue(xmlDoc, "remoteurl"),
        chat_links: chatLinks,
        promo_enabled: parseInt(promoenabled, 10),
        promo_balance: parseInt(promobalance, 10),
        walletApi
    }
}

export async function login(login, password, useHash) {

    const xml =
        `<request>
            <version>${version}</version>
            <user_id>${login}</user_id>
            <user_password>${password}</user_password>
            <use_hash>${useHash ? 1 : 0}</use_hash>
            <operation>check</operation>
            <host>${window.location.host}</host>
            <show_packet>1</show_packet></request>`

    const payload = {
        reg_xml: btoa(xml),
        reg_sign: md5("hall_secret" + xml + "hall_secret")
    };

    const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
    };

    const response = await fetch(`${httpServerHost}/remotecab_session`, options);
    const text = await response.text();
    const xmlText = '<?xml version="1.0"?>' + atob(text.replace(/\n/g, ''));
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "text/xml");

    const success = xmlNodeValue(xmlDoc, "status") === "success";
    if (!success) {
        throw new Error("login failed");
    }

    let chatLinks = [];
    let promoenabled = "0";
    let promobalance = "0";
    try {
        chatLinks = xmlNodeValue(xmlDoc, "social") || [];
        promoenabled = xmlNodeValue(xmlDoc, "promoenabled") || "0";
        promobalance = xmlNodeValue(xmlDoc, "promobalance") || "0";
    } catch (e) { }

    let walletApi = "";
    try {
        walletApi = xmlNodeValue(xmlDoc, "walletapi") || "";
    } catch (e) { }

    return {
        uid: +xmlNodeValue(xmlDoc, "uid"),
        sid: +xmlNodeValue(xmlDoc, "sessionid"),
        key1: +xmlNodeValue(xmlDoc, "key1"),
        key2: +xmlNodeValue(xmlDoc, "key2"),
        hall_id: +xmlNodeValue(xmlDoc, "hall_id"),
        remote_url: xmlNodeValue(xmlDoc, "remoteurl"),
        chat_links: chatLinks,
        promo_enabled: parseInt(promoenabled, 10),
        promo_balance: parseInt(promobalance, 10),
        walletApi,
    }
}
