import Bowser from "bowser"

const browser = Bowser.getParser(window.navigator.userAgent);

export function isTablet() {
    return browser.getPlatformType().toLowerCase().includes("tablet");
}

export function isMobile() {
    return browser.getPlatformType().toLowerCase().includes("mobile");
}

export function isDesktop() {
    return browser.getPlatformType().toLowerCase().includes("desktop");
}

export function isiOS() {
    return browser.getOSName().toLowerCase().includes("ios");
}

export function isAndroid() {
    return browser.getOSName().toLowerCase().includes("android");
}
