import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import Avatar, { avatarsPath } from "../avatars/Avatars";
import { CloseButton, PlayButton } from "../common/Buttons";
import { GameCard } from '../card/Card';
import {
    GameFrame,
    Header,
    HeaderControls,
    NoRoomsNotification,
    ProgressWrapper,
    RoomsWrapper,
    UserBlock,
} from './Lobby.style';
import Menu from "../menu/Menu";
import ProgressSpinner from '../common/ProgressSpinner';
import { startFullScreen, stopFullScreen, } from '../../utils/fullscreen';
import {
    subscribeUserRequest,
    getBalanceRequest,
    exitRoomRequest,
    setAvatarsList,
} from '../../actions/index';

Modal.setAppElement('#root');

const modalStyle = {
    content: {
        border: 'none',
        bottom: 0,
        height: '100%',
        left: 0,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
    },
    overlay: {
        background: '#000',
        opacity: 1,
        zIndex: 100
    }
};

const StyledHeaderControls = styled(HeaderControls)`
    display: flex;
    position: fixed;
    top: 8px;
    right: 8px;

    ${CloseButton} {
        margin-right: 1em;
        &:last-child {
            margin-right: 0;
        }
    }
`;

function Lobby({
    session,
    selector,
    exitRoom,
    setAvatarsList,
    subscribe,
    updateBalance,
}) {

    const subscribed = selector.subscribed;
    const waiting = selector.waitSubscribe;
    const rooms = selector.rooms[selector.selectedType];
    const failed = selector.failed || (!waiting && rooms === undefined);

    const [frameShown, setFrameShown] = React.useState(false);
    const [frameSrc, setFrameSrc] = React.useState("");
    const [avatar, setAvatar] = React.useState(null);
    const [avatarMenuShown, setAvatarMenuShown] = React.useState(false);

    useEffect(() => {

        const handleRotation = () => {
            const main = window.document.getElementById('live-selector');
            if (main) {
                main.style.overflowY = "hidden";
                setTimeout(() => {
                    main.style.overflowY = "auto";
                }, 100);
            }
        };

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", handleRotation, false);
        }

        return () => {
            window.removeEventListener("orientationchange", handleRotation);
        }
    });

    useEffect(() => {
        const handleGameMessage = event => {
            var msg = event.data.msg;
            switch (msg) {
                case "panic":
                    if (session.standalone) {
                        window.location.replace(event.data.url);
                    } else {
                        window.parent.postMessage({ msg: 'panic', url: event.data.url }, '*');
                    }
                    break;
                case "closeGame":
                    setFrameShown(false);
                    break;
                case "startFullScreen":
                    if (session.standalone) {
                        startFullScreen();
                    } else {
                        window.parent.postMessage({ msg: "startFullScreen" }, '*');
                    }
                    break;
                case "stopFullScreen":
                    if (session.standalone) {
                        stopFullScreen();
                    } else {
                        window.parent.postMessage({ msg: "stopFullScreen" }, '*');
                    }
                    break;
                default: break;
            }
        }

        window.addEventListener("message", handleGameMessage);

        return () => {
            window.removeEventListener("message", handleGameMessage);
        }

    }, [session.standalone]);

    useEffect(() => {
        const fetchAvatarsList = async () => {
            const response = await fetch(`${avatarsPath}/avatars.json`);
            const avatars = await response.json();
            setAvatarsList(avatars);
        }
        fetchAvatarsList();
    }, [setAvatarsList]);

    useEffect(() => {
        if (session.avatar && selector.avatars.length > 0) {
            const avatar = selector.avatars.find(item => item.key === session.avatar);
            if (avatar) {
                setAvatar(avatar.url);
            }
        }

        return () => { }

    }, [session.avatar, selector.avatars, setAvatar]);

    useEffect(() => {
        const timer = setInterval(() => {
            updateBalance();
        }, 15000);
        updateBalance();
        subscribe();

        return () => {
            clearInterval(timer);
        }
    }, [updateBalance, subscribe]);

    function onCloseClick(e) {
        e.preventDefault();
        if (session.standalone) {
            localStorage.removeItem("stargame.live.autologin");
            window.location.reload();
        } else {
            window.parent.postMessage({ msg: 'closeGame' }, '*');
        }
    }

    function onPlayClick(e, props) {
        e.preventDefault();

        const tableId = props.tableId;
        const tableType = props.type;

        let src = "";

        if (tableId === "wt8u3fab6z71q8nc") {
            src = `/games/tvbet-live/index.html?type=tvbet-keno&table=${tableId}`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/tvbet-live/index.html?type=tvbet-keno&table=${tableId}`;
            }
        } else if (["UTHTable00000001", "CSPTable00000001", "TRPTable00000001"].indexOf(tableId) !== -1) {
            const cat = tableId.slice(0, 3).toLowerCase();
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=${cat}&game=${cat}`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=${cat}&game=${cat}`;
            }
        } else if (["SuperSicBo000001"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=sicbo&game=sicbo`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=sicbo&game=sicbo`;
            }
        } else if (["MegaBall00000001"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=megaball&game=megaball`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=megaball&game=megaball`;
            }
        } else if (["MOWDream00000001"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=moneywheel&game=moneywheel`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=moneywheel&game=moneywheel`;
            }
        } else if (["dealnodeal000001"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=dealnodeal&game=dealnodeal`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=dealnodeal&game=dealnodeal`;
            }
        } else if (["DragonTiger00001"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=dragontiger&game=dragontiger`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&category=dragontiger&game=dragontiger`;
            }
        } else if (["mrfykemt5slanyi5"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&game=scalableblackjack`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index9.html?table_id=${tableId}&game=scalableblackjack`;
            }
        } else if (["3v12py4p4aihrezp", "99tk73qhhc826l26"].indexOf(tableId) !== -1) {
            let rouletteId = 1;
            if (tableId === "99tk73qhhc826l26") {
                rouletteId = 2;
            }
            src = `/games/xpg/index.html?id=${rouletteId}&category=roulette&server=wss://59138.stargamegames.com:7274&table_id=${tableId}`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/xpg/index.html?id=${rouletteId}&category=roulette&server=wss://59138.stargamegames.com:7274&table_id=${tableId}`;
            }
        } else if (["w33p1pzvahymhfj6", "zz15hk1j38fe1ay9"].indexOf(tableId) !== -1) {
            let baccaratId = 10;
            if (tableId === "zz15hk1j38fe1ay9") {
                baccaratId = 28;
            }
            src = `/games/xpg/index.html?id=${baccaratId}&category=baccarat&server=wss://59138.stargamegames.com:7274&table_id=${tableId}`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/xpg/index.html?id=${baccaratId}&category=baccarat&server=wss://59138.stargamegames.com:7274&table_id=${tableId}`;
            }
        } else if (["ndgvz5mlhfuaad6e", "ocye5hmxbsoyrcii", "onokyd4wn7uekbjx", "NoCommBac0000001", "zixzea8nrf1675oh", "ndgvwvgthfuaad3q", "leqhceumaq6qfoug", "ndgvs3tqhfuaadyg", "60i0lcfx5wkkv3sy", "k2oswnib7jjaaznw", "LightningBac0001", "oytmvb9m1zysmc44", "lv2kzclunt2qnxo5", "qgdk6rtpw6hax4fe", "ndgv45bghfuaaebf", "nmwde3fd7hvqhq43", "nmwdzhbg7hvqh6a7", "nxpj4wumgclak2lx", "nxpkul2hgclallno", "obj64qcnqfunjelj", "ocye2ju2bsoyq6vv", "ovu5cwp54ccmymck", "ovu5dsly4ccmynil", "ovu5eja74ccmyoiq", "ovu5fbxm4ccmypmb", "ovu5fzje4ccmyqnr", "o4kyj7tgpwqqy4m4", "o4kylkahpwqqy57w", "o4kymodby2fa2c7g"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index8.html?table_id=${tableId}&category=baccarat&game=baccarat`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index8.html?table_id=${tableId}&category=baccarat&game=baccarat`;
            }
        } else if (["n4jwxsz2x4tqitvx", "lkcbrbdckjxajdol", "8clwnwrupuvf0osq", "o4vjrhh5rtwimgi3", "r5aw9yumyaxgnd90", "f1f4rm9xgh4j3u2z", "SpeedAutoRo00001", "48z5pjps3ntvqc1b", "vctlz20yfnmp1ylr", "7x0b1tgh7agmf6hv", "wzg6kdkad1oe7m5k", "zosmk25g2f768o52", "LightningTable01", "XxxtremeLigh0001", "TRLRou0000000001", "AmericanTable001"].indexOf(tableId) !== -1) {
            src = `/games/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index8.html?table_id=${tableId}&category=roulette&game=roulette`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/evolution-live/live.fantasysportnetwork.com/frontend/evo/r2/index8.html?table_id=${tableId}&category=roulette&game=roulette`;
            }
        } else {
            src = `/games/live/index.html?type=${tableType}&id=${tableId}`;
            if (session.external) {
                src = `https://apiserver.solutions/public/tote/live/index.html?type=${tableType}&id=${tableId}`;
            }
        }

        setFrameSrc(src);
        setFrameShown(true);
    }

    function onCloseAvatarClick(e) {
        e.preventDefault();
        setAvatarMenuShown(false);
    }

    function onShowAvatarClick(e, props) {
        e.preventDefault();
        setAvatarMenuShown(true);
    }

    function onGameOpened() {
        window.parent.postMessage({ msg: 'lobbyGameOpened' }, '*');
    }

    function onGameClosed() {
        window.parent.postMessage({ msg: 'lobbyGameClosed' }, '*');
        const shutdown = () => {
            if (session.standalone) {
                localStorage.removeItem("stargame.live.autologin");
                window.location.reload();
            } else {
                window.parent.postMessage({ msg: 'closeGame' }, '*');
            }
        }

        if (!window.socket || !window.socket.connected) {
            shutdown();
        } else {
            window.socket.onDisconnect = () => {
                shutdown();
            }
            updateBalance();
            exitRoom();
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={frameShown}
                style={{ ...modalStyle, content: { ...modalStyle.content } }}
                onAfterOpen={onGameOpened}
                onAfterClose={onGameClosed}
            >
                <GameFrame
                    title="game"
                    frameBorder="0"
                    allowFullScreen={true}
                    scrolling="no"
                    src={frameSrc}>

                </GameFrame>
            </Modal>
            {avatarMenuShown && <Avatar></Avatar>}
            {waiting &&
                <ProgressWrapper>
                    <ProgressSpinner />
                    <span>Please wait...</span>
                </ProgressWrapper>
            }
            <Header>
                <UserBlock avatar={avatar}>
                    <span
                        className="user-block__avatar"
                        onClick={onShowAvatarClick}>
                        {`${session.name && !session.name.includes("-fast") ? session.name : session.uid}`}
                    </span>
                    {selector.balance !== null &&
                        <span className="user-block__balance">
                            {`${selector.balance}`}
                        </span>
                    }
                </UserBlock>
                {subscribed &&
                    <Menu className={avatarMenuShown ? 'hidden' : ''} />
                }
                {!session.embedded &&
                    <StyledHeaderControls>
                        <CloseButton onClick={onCloseClick}></CloseButton>
                    </StyledHeaderControls>
                }
            </Header>
            <main id="live-selector">
                <>
                    {failed &&
                        <NoRoomsNotification>
                            Sorry, there are no rooms available for the selected category right now.
                        </NoRoomsNotification>
                    }
                    {!waiting && !failed &&
                        <RoomsWrapper className={avatarMenuShown ? 'hidden' : ''}>
                            {
                                rooms.sort((a, b) => a.order - b.order).map((item, index) => {
                                    return (
                                        <GameCard
                                            key={index}
                                            gameProps={item}
                                            onPlayClicked={onPlayClick}
                                        >
                                        </GameCard>
                                    )
                                })}
                        </RoomsWrapper>
                    }
                </>
            </main>
            {avatarMenuShown &&
                <footer>
                    <PlayButton onClick={onCloseAvatarClick}>Back to games</PlayButton>
                </footer>
            }
        </React.Fragment >
    );
}

function mapStateToProps(state) {
    const session = state.authentication;
    const selector = state.selector;
    return { session, selector };
}

function mapDispatchToProps(dispatch) {
    return {
        subscribe: () => dispatch(subscribeUserRequest()),
        updateBalance: () => dispatch(getBalanceRequest()),
        exitRoom: () => dispatch(exitRoomRequest()),
        setAvatarsList: (avatars) => dispatch(setAvatarsList(avatars))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);
